import { CardActionArea, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionsStyled,
  CardStyled,
  ContainerStyled,
  GridContainer,
  GridItem,
  LoadMoreBox,
} from "./generic-list-page.styles";
import { BaseLoader } from "~/common/components/base-loader/base-loader";

interface GenericListPageProps<T> {
  title: string;
  fetchItems: (
    page: number,
    pageSize: number,
  ) => Promise<{ response: { data: T[]; total: number } }>;
  pageSize: number;
  getItemLink: (item: T) => string;
  renderItemContent: (item: T) => React.ReactNode;
  renderItemActions?: (item: T) => React.ReactNode;
}

const GenericListPage = <T,>({
  title,
  fetchItems,
  pageSize,
  getItemLink,
  renderItemContent,
  renderItemActions,
}: GenericListPageProps<T>) => {
  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const isEmpty = !items.length;
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (page: number) => {
      try {
        setIsFetchingMore(true);
        const { response } = await fetchItems(page, pageSize);
        if (page === 1) {
          setItems(response.data);
        } else {
          setItems((prev) => [...prev, ...response.data]);
        }
        setHasMore(page * pageSize < response.total);
      } catch (error) {
        console.error("Erro ao buscar itens:", error);
      } finally {
        setLoading(false);
        setIsFetchingMore(false);
      }
    },
    [fetchItems, pageSize],
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, fetchData]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200 &&
      !isFetchingMore &&
      hasMore
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [isFetchingMore, hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  if (loading) {
    return <BaseLoader />;
  } else if (isEmpty) {
    return (
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          mt: 5,
          color: "text.secondary",
          height: "calc(40vh - 64px)",
        }}
      >
        Nenhum item encontrado.
      </Typography>
    );
  }

  return (
    <ContainerStyled>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          mb: 3,
          color: "primary.main",
        }}
      >
        {title}
      </Typography>
      <GridContainer>
        {items.map((item: any) => (
          <GridItem key={item.id || Math.random()}>
            <CardStyled>
              <CardActionArea onClick={() => navigate(getItemLink(item))}>
                {renderItemContent(item)}
              </CardActionArea>
              {renderItemActions && (
                <CardActionsStyled>{renderItemActions(item)}</CardActionsStyled>
              )}
            </CardStyled>
          </GridItem>
        ))}
      </GridContainer>
      {isFetchingMore && (
        <LoadMoreBox>
          <CircularProgress />
        </LoadMoreBox>
      )}
      {!isEmpty && !hasMore && !loading && (
        <Typography
          variant="body2"
          sx={{ textAlign: "center", mt: 5, color: "text.secondary" }}
        >
          Você chegou ao final da lista.
        </Typography>
      )}
    </ContainerStyled>
  );
};

export default GenericListPage;
