import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo_azul from "~/common/assets/logo_azul.webp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Person from "@mui/icons-material/Person";
import { Menu as MenuIcon, ShoppingCart } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Role } from "~/common/types/user-session";
import { CartItem } from "~/redux/slicers/cartSlicer";
import { ApplicationState } from "~/redux/store";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { logout } from "~/redux/slicers/authSlicer";

const Header = ({ userName = "", userRole = "" }) => {
  const theme = useTheme();
  const isMdOnly = useMediaQuery(theme.breakpoints.only("md"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isXsDown = useMediaQuery("(max-width:430px)");
  const isDownThen1400 = useMediaQuery("(max-width:1400px)");
  const isMobile = isMdOnly || isMdDown;
  const navigate = useNavigate();

  const location = useLocation();
  const isCourseDetails = location.pathname.startsWith("/aluno/cursos/");
  const toolbarPaddingY = isCourseDetails ? 1 : isXsDown ? 1 : 2;

  const cartItemsCount = useSelector((state: ApplicationState) =>
    state.cart.items.reduce(
      (acc: number, item: CartItem) => acc + item.quantity,
      0,
    ),
  );

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLElement | null>(
    null,
  );
  const [coursesMenuAnchor, setCoursesMenuAnchor] =
    useState<HTMLElement | null>(null);
  const [mobileCoursesOpen, setMobileCoursesOpen] = useState(false);
  const dispatch = useDispatch();

  let menuItems = [
    { label: "Cursos", submenu: ["Cursos Presenciais", "Cursos Online"] },
    { label: "Apostilas", route: "/apostilas" },
    { label: "Notícias", route: "/noticias" },
    { label: "Aprovados", route: "/aprovados" },
    { label: "Fale Conosco", route: "/fale-conosco" },
    {
      label: "Fotos",
      route: "/fotos",
    },
  ];

  if (userRole === Role.CLIENTE || userRole === Role.VISITANTE) {
    menuItems = menuItems.filter((item) => item.label !== "Fale Conosco");
  }

  if (userRole === Role.ADMIN || userRole === Role.ALUNO) {
    menuItems = menuItems.filter((item) => item.label !== "Fotos");
    menuItems = menuItems.filter((item) => item.label !== "Fale Conosco");
    menuItems.push({ label: "Área do Aluno", route: "/aluno" });
    menuItems.push({ label: "Fotos", route: "/fotos" });
  }

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const handleCoursesMenu = (event: React.MouseEvent<HTMLElement>) => {
    setCoursesMenuAnchor(event.currentTarget);
  };

  const handleCoursesMenuClose = () => {
    setCoursesMenuAnchor(null);
  };

  const handleMobileCoursesToggle = () => {
    setMobileCoursesOpen(!mobileCoursesOpen);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white", boxShadow: 1 }}>
      <Container maxWidth="xl" sx={{ px: isXsDown ? 1 : 2 }}>
        <Toolbar
          sx={{
            py: toolbarPaddingY,
            minHeight: isXsDown ? "56px !important" : "auto",
            px: isXsDown ? "0 !important" : 2,
          }}
        >
          {isMobile && (
            <IconButton
              onClick={() => setMobileMenuOpen(true)}
              aria-label="Abrir menu de navegação"
              sx={{
                color: "text.primary",
                "& svg": {
                  width: isXsDown ? 24 : 28,
                  height: isXsDown ? 24 : 28,
                },
                padding: isXsDown ? 0.5 : 1,
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            onClick={() => navigate("/")}
            sx={{
              cursor: "pointer",
              flexShrink: 0,
              mx: { xs: 0.5, sm: 1.5, md: 3 },
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo_azul}
              alt="Logo"
              className={
                isCourseDetails ? "logo-image course-details" : "logo-image"
              }
              loading="lazy"
            />
          </Box>

          {!isMobile && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
                gap: 5,
              }}
            >
              {menuItems.map((item) =>
                item.submenu ? (
                  <Button
                    key={item.label}
                    onClick={handleCoursesMenu}
                    endIcon={
                      <ArrowDropDownIcon sx={{ width: 24, height: 24 }} />
                    }
                    sx={{
                      color: "text.primary",
                      textTransform: "none",
                      fontSize: isDownThen1400 ? "1rem" : "1.25rem",
                      fontWeight: 500,
                      py: 1,
                      "&:hover": {
                        color: "primary.main",
                      },
                      minWidth: "120px",
                    }}
                  >
                    {item.label}
                  </Button>
                ) : (
                  <Button
                    key={item.label}
                    onClick={() => navigate(item.route)}
                    sx={{
                      color: "text.primary",
                      textTransform: "none",
                      fontSize: isDownThen1400 ? "1rem" : "1.25rem",
                      fontWeight: 500,
                      py: 1,
                      "&:hover": {
                        color: "primary.main",
                      },
                      minWidth: isDownThen1400 ? "80px" : "110px",
                    }}
                  >
                    {item.label}
                  </Button>
                ),
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isXsDown ? 1 : 3,
              ml: "auto",
            }}
          >
            {userName ? (
              <Button
                onClick={handleUserMenu}
                startIcon={
                  <Avatar
                    sx={{
                      bgcolor: "primary.main",
                      width: isXsDown ? 32 : 38,
                      height: isXsDown ? 32 : 38,
                      fontSize: isXsDown ? "1rem" : "1.2rem",
                    }}
                  >
                    {userName.charAt(0)}
                  </Avatar>
                }
                endIcon={
                  isXsDown ? null : (
                    <ArrowDropDownIcon sx={{ width: 24, height: 24 }} />
                  )
                }
                sx={{
                  color: "text.primary",
                  textTransform: "none",
                  fontSize: "1.1rem",
                  fontWeight: 500,
                  minWidth: isXsDown ? "40px" : "160px",
                  p: isXsDown ? "4px" : "6px 8px",
                }}
              >
                {isMobile ? "" : `Olá, ${userName.split(" ")[0]}`}
              </Button>
            ) : (
              <IconButton
                onClick={() => navigate("/login")}
                aria-label="Abrir tela de login"
                sx={{
                  color: "text.primary",
                  p: isXsDown ? 0.5 : 1,
                  minWidth: isXsDown ? "30px" : "auto",
                }}
              >
                <Person
                  sx={{
                    width: isXsDown ? 24 : 28,
                    height: isXsDown ? 24 : 28,
                  }}
                />
              </IconButton>
            )}

            <Badge
              badgeContent={cartItemsCount}
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  mr: isXsDown ? 0.8 : 0,
                  fontSize: isXsDown ? "0.75rem" : "0.9rem",
                  height: isXsDown ? 18 : 22,
                  minWidth: isXsDown ? 18 : 22,
                },
              }}
            >
              <IconButton
                onClick={() => navigate("/carrinho")}
                aria-label="Abrir carrinho de compras"
                sx={{
                  color: "text.primary",
                  p: isXsDown ? 0.5 : 1,
                  "& svg": {
                    width: isXsDown ? 24 : 28,
                    height: isXsDown ? 24 : 28,
                  },
                }}
              >
                <ShoppingCart />
              </IconButton>
            </Badge>
          </Box>
        </Toolbar>
      </Container>

      {!isMobile && (
        <Menu
          anchorEl={coursesMenuAnchor}
          open={Boolean(coursesMenuAnchor)}
          onClose={handleCoursesMenuClose}
          aria-expanded={Boolean(coursesMenuAnchor)}
          aria-controls="courses-menu"
          PaperProps={{
            sx: {
              mt: 1,
              "& .MuiMenuItem-root": {
                fontSize: "1.1rem",
                py: 1.5,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/cursos-presenciais");
              handleCoursesMenuClose();
            }}
          >
            Cursos Presenciais
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/cursos-gravados");
              handleCoursesMenuClose();
            }}
          >
            Cursos Online
          </MenuItem>
        </Menu>
      )}

      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <Box
          sx={{
            width: 280,
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <List>
            {menuItems.map((item) =>
              item.submenu ? (
                <Box key={item.label}>
                  <ListItemButton onClick={handleMobileCoursesToggle}>
                    <ListItemText primary={item.label} />
                    {mobileCoursesOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={mobileCoursesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => {
                          navigate("/cursos-presenciais");
                          setMobileMenuOpen(false);
                          setMobileCoursesOpen(false);
                        }}
                      >
                        <ListItemText primary="Cursos Presenciais" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => {
                          navigate("/cursos-gravados");
                          setMobileMenuOpen(false);
                          setMobileCoursesOpen(false);
                        }}
                      >
                        <ListItemText primary="Cursos Online" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </Box>
              ) : (
                <MenuItem
                  key={item.label}
                  onClick={() => {
                    navigate(item.route);
                    setMobileMenuOpen(false);
                  }}
                >
                  {item.label}
                </MenuItem>
              ),
            )}
          </List>
        </Box>
      </Drawer>

      <Menu
        anchorEl={userMenuAnchor}
        open={Boolean(userMenuAnchor)}
        onClose={() => setUserMenuAnchor(null)}
        PaperProps={{
          sx: {
            mt: 1,
            "& .MuiMenuItem-root": {
              fontSize: "1.1rem",
              py: 1.5,
            },
          },
        }}
      >
        {(userRole === Role.ADMIN || userRole === Role.ALUNO) && (
          <MenuItem
            onClick={() => {
              navigate("/aluno");
              setUserMenuAnchor(null);
            }}
          >
            Área do Aluno
          </MenuItem>
        )}
        {userRole === Role.ADMIN ? (
          <MenuItem
            onClick={() => {
              navigate("/admin");
              setUserMenuAnchor(null);
            }}
          >
            Meu Painel
          </MenuItem>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                navigate("/perfil");
                setUserMenuAnchor(null);
              }}
            >
              Meu Perfil
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            navigate("/pedidos");
            setUserMenuAnchor(null);
          }}
        >
          Meus Pedidos
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/downloads");
            setUserMenuAnchor(null);
          }}
        >
          Downloads
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/", {
              replace: true,
            });
            dispatch(logout());
            setUserMenuAnchor(null);
          }}
        >
          Sair
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Header;
