import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticate } from "~/features/login/services/auth";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { LockIcon, MailIcon } from "lucide-react";

interface AuthModalProps {
  open: boolean;
  handleClose: () => void;
  redirectPath: string;
}

const AuthModal: React.FC<AuthModalProps> = ({
  open,
  handleClose,
  redirectPath,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userMail, setUserMail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await authenticate({ email: userMail, password, rememberMe }, dispatch);
      navigate(redirectPath, { replace: true });
      setIsLoading(false);
      handleClose();
    } catch (error) {
      const errorResponse = error as {
        response: { data: { message: string } };
      };
      setErrorMessage(errorResponse.response?.data.message);
      setIsLoading(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="auth-modal-title"
      aria-describedby="auth-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, md: 400 },
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="auth-modal-title"
          variant="h6"
          component="h2"
          mb={2}
          textAlign="center"
        >
          Fazer Login
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          value={userMail}
          onChange={(e) => setUserMail(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon color="#000" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Senha"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color="#000" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mt={1}
          mb={2}
        >
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            style={{ marginRight: 8, marginLeft: 2 }}
          />
          <Typography variant="body2">Lembrar-me</Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 1 }}
          onClick={handleSubmit}
          disabled={!userMail || !password || isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color={"inherit"} />
          ) : (
            "Entrar"
          )}
        </Button>

        <Button
          variant="text"
          color="secondary"
          fullWidth
          sx={{ mt: 1 }}
          onClick={handleClose}
        >
          Cancelar
        </Button>

        <Button
          variant="text"
          color="primary"
          sx={{
            alignSelf: "flex-start",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          }}
          onClick={() => navigate("/recuperar-senha/solicitar")}
        >
          Esqueceu sua senha?
        </Button>

        {errorMessage && (
          <Typography color="error" mt={2} textAlign={"center"}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default AuthModal;
