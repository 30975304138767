import { useSelector } from "react-redux";
import Footer from "../footer/footer";
import { FirstHeaderSession } from "../header/first-header-session/first-header-session";
import Header from "../header/header";
import { ApplicationState } from "~/redux/store";

interface Props {
  children: React.ReactNode;
  firstHeaderOnly?: boolean;
}

export const BaseScreen = ({ children, firstHeaderOnly = false }: Props) => {
  const user = useSelector((state: ApplicationState) => state.auth.user);

  return (
    <>
      <FirstHeaderSession />
      {!firstHeaderOnly && (
        <Header userName={user?.name ?? ""} userRole={user?.role} />
      )}
      {children}
      <Footer />
    </>
  );
};
