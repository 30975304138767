import axios from "axios";
import {
  authorizationError,
  sessionExpired,
} from "~/redux/slicers/sessionSlicer";
import { ApplicationState, store } from "~/redux/store";

export interface PaginationResponse<T> {
  data: T;
  pagination: {
    total: number;
    page: number;
    limit: number;
  };
}

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

baseApi.interceptors.request.use(
  (config) => {
    const state: ApplicationState = store.getState();
    const { isLogged, token } = state.auth;

    if (isLogged && token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["ngrok-skip-browser-warning"] = "69420";
    }

    return config;
  },
  (error) => Promise.reject(error),
);

baseApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch(sessionExpired());
    } else if (error.response?.status === 403) {
      store.dispatch(authorizationError());
    }
    return Promise.reject(error);
  },
);

export default baseApi;
