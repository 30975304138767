import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { store } from "~/redux/store";
import { closeSessionExpiredModal } from "~/redux/slicers/sessionSlicer";

export const SessionExpireModal = () => (
  <Dialog open onClose={() => store.dispatch(closeSessionExpiredModal())}>
    <DialogTitle>Sessão expirada</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Sua sessão expirou. Por favor, faça login novamente.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          localStorage.clear();
          sessionStorage.clear();
          store.dispatch(closeSessionExpiredModal());
          window.location.href = "/";
        }}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
);
