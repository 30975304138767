import { Snackbar } from "@mui/material";
import { useMediaQuery } from "@mui/system";
import theme from "~/theme/theme";
import { ReactElement } from "react";

interface CustomSnackBarProps {
  approvalSnackbarOpen: boolean;
  handleCloseApprovalSnackbar: () => void;
  children: ReactElement;
  timeToClose?: number;
}

export function CustomSnackBar({
  approvalSnackbarOpen,
  handleCloseApprovalSnackbar,
  children,
  timeToClose = 4000,
}: CustomSnackBarProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={approvalSnackbarOpen}
      autoHideDuration={timeToClose}
      onClose={handleCloseApprovalSnackbar}
      anchorOrigin={
        isMobile
          ? { vertical: "bottom", horizontal: "center" }
          : { vertical: "top", horizontal: "right" }
      }
    >
      {children}
    </Snackbar>
  );
}
