import { Box, Card, CardActions, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";

export const ContainerStyled = styled(Container)({
  marginTop: "40px",
  marginBottom: "80px",
  textAlign: "center",
});

export const GridContainer = styled(Grid)({
  display: "flex",
  flexWrap: "wrap",
  gap: "24px",
  justifyContent: "center",
});

export const GridItem = styled(Grid)({
  flex: "1 1 calc(33.333% - 24px)",
  maxWidth: "calc(33.333% - 24px)",
  minWidth: "300px",
});

export const CardStyled = styled(Card)({
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "translateY(-4px)",
  },
});

export const BoxStyled = styled(Box)({
  padding: "16px",
  textAlign: "center",
});

export const LoadMoreBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
});

export const CardActionsStyled = styled(CardActions)({
  justifyContent: "center",
});
