export enum UserType {
  CLIENTE = "CLIENTE",
  ESTUDANTE = "ESTUDANTE",
  VISITANTE = "VISITANTE",
}

export interface UserProps {
  cpf: string;
  nome: string;
  dataNascimento: string;
  endereco: string;
  cep: string;
  cidade: string;
  uf: string;
  numResidencial: string;
  numTelefone: string;
  email: string;
  nivelEscolar: string;
  bairro: string;
  observacao?: string;
  senha: string;
  tipoUsuario?: UserType;
  cursosIds?: number[] | null;
  apostilasIds?: number[] | null;
}

class User {
  public userData: UserProps;
  constructor(userData: UserProps) {
    this.userData = userData;
  }
}

export default User;
