import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { closeAuthorizationErrorModal } from "~/redux/slicers/sessionSlicer";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const AuthorizationErrorModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/", {
      replace: true,
    });
    dispatch(closeAuthorizationErrorModal());
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="authorization-error-title"
      aria-describedby="authorization-error-description"
    >
      <Box sx={style}>
        <Typography id="authorization-error-title" variant="h6" component="h2">
          Acesso Restrito
        </Typography>
        <Typography id="authorization-error-description" sx={{ mt: 2 }}>
          Você não tem permissão para acessar este recurso.
        </Typography>
        <Box sx={{ mt: 4, textAlign: "right" }}>
          <Button variant="contained" onClick={handleClose}>
            Fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AuthorizationErrorModal;
