import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  Link,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  footerContainer,
  linkStyle,
  iconButtonStyle,
  footerBottom,
} from "./footer.styles";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box sx={footerContainer}>
      <Container>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Fale Conosco
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <PhoneIcon sx={{ fontSize: 24, marginRight: 1 }} />
              <Typography
                component="a"
                href="tel:+5518997261599"
                aria-label="Ligar para (18) 99726-1599"
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: 16,
                }}
              >
                (18) 99726-1599
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <EmailIcon sx={{ fontSize: 24, marginRight: 1 }} />
              <Typography
                component="a"
                href="mailto:contato@crcursospreparatorios.com"
                aria-label="Enviar email para contato@crcursospreparatorios.com"
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: 16,
                }}
              >
                contato@crcursospreparatorios.com
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationOnIcon sx={{ fontSize: 24, marginRight: 1 }} />
              <Typography sx={{ fontSize: 16 }}>
                Rua Paraguai, 143, Vila Geni, Presidente Prudente - SP
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Nossos Serviços
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {[
                { label: "Home", href: "/" },
                { label: "Fale conosco", href: "/fale-conosco" },
                { label: "Cursos", href: "/cursos-gravados" },
                { label: "Notícias", href: "/noticias" },
                { label: "Apostilas", href: "/apostilas" },
                { label: "Lista de Aprovados", href: "/aprovados" },
                { label: "Galeria de Fotos", href: "/fotos" },
              ].map((link, index) => (
                <Link
                  key={index}
                  underline="hover"
                  color="inherit"
                  sx={{
                    ...linkStyle,
                    fontSize: 16,
                  }}
                  aria-label={`Ir para ${link.label}`}
                  onClick={() => {
                    navigate(link.href);
                  }}
                >
                  {link.label}
                </Link>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Redes Sociais
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1.5,
              }}
            >
              <IconButton
                href="https://www.facebook.com/wwwcrcursospreparatorios"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ ...iconButtonStyle, width: 40, height: 40 }}
                aria-label="Acesse nossa página no Facebook"
              >
                <FacebookIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/crcursospreparatorios1/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ ...iconButtonStyle, width: 40, height: 40 }}
                aria-label="Siga-nos no Instagram"
              >
                <InstagramIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                href="https://wa.me/5518997261599"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ ...iconButtonStyle, width: 40, height: 40 }}
                aria-label="Entre em contato pelo WhatsApp"
              >
                <WhatsAppIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/c/CRCursosPreparatorios"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ ...iconButtonStyle, width: 40, height: 40 }}
                aria-label="Inscreva-se no nosso canal do YouTube"
              >
                <YouTubeIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ ...footerBottom, mt: 4 }}>
          <Typography variant="body2">
            Copyright ©2024 Todos os direitos reservados | Powered by CR Cursos
            Preparatórios
          </Typography>
          <Typography variant="body2">
            <Link
              color="inherit"
              href="/politica-de-privacidade"
              sx={{ ...linkStyle, fontSize: 14 }}
              aria-label="Ver nossa Política de Privacidade"
            >
              Política de Privacidade
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
