import React from "react";
import {
  Container,
  Box,
  Typography,
  Divider,
  Stack,
  IconButton,
  Tooltip,
  Fade,
  Alert,
} from "@mui/material";
import { useInView } from "react-intersection-observer";
import { BaseLoader } from "~/common/components/base-loader/base-loader";
import { useIsMobile } from "~/common/hooks/use-is-mobile";
import {
  AnimatedContainer,
  Header,
  HeaderContent,
  ImageWrapper,
  pageAnimationVariants,
  ShareActions,
  ShareText,
  StyledImage,
  Subtitle,
  Title,
} from "./generic-detail-page.styles";
import { CustomSnackBar } from "~/common/components/custom-snackbar";
import {
  Facebook,
  Instagram,
  Share,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";

interface GenericDetailPageProps<T> {
  id: number;
  fetchItem: (id: number) => Promise<{ response: T }>;
  renderContent: (item: T) => React.ReactNode;
  enableSharing?: boolean;
  getTitle: (item: T) => string;
  getSubtitle?: (item: T) => string;
  getImageUrl?: (item: T) => string;
}

const GenericDetailPage = <T,>({
  id,
  fetchItem,
  renderContent,
  enableSharing = false,
  getTitle,
  getSubtitle,
  getImageUrl,
}: GenericDetailPageProps<T>) => {
  const isMobile = useIsMobile();
  const [item, setItem] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const { ref: headerRef, inView: headerInView } = useInView({
    threshold: 0.3,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchItem(id);
        setItem(data.response);
      } catch (error) {
        console.error("Error fetching item:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, fetchItem]);

  if (loading) {
    return <BaseLoader />;
  }

  if (!item) {
    return (
      <Box textAlign="center" py={8}>
        <Typography variant="h5">Item not found</Typography>
      </Box>
    );
  }

  const shareLinks = enableSharing
    ? {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`,
        twitter: `https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(getTitle(item))}`,
        whatsapp: `https://wa.me/?text=${encodeURIComponent(`${getTitle(item)} - ${window.location.href}`)}`,
        instagram: window.location.href,
      }
    : null;

  const handleCopyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      setSnackbarOpen(true);
    });
  };

  return (
    <AnimatedContainer
      variants={pageAnimationVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      <Header ref={headerRef}>
        <HeaderContent>
          {getImageUrl && (
            <ImageWrapper>
              <StyledImage
                src={getImageUrl(item)}
                alt={getTitle(item)}
                loading="lazy"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </ImageWrapper>
          )}
          <Fade in={headerInView} timeout={1000}>
            <Box>
              <Title variant={isMobile ? "h4" : "h3"}>{getTitle(item)}</Title>
              {getSubtitle && (
                <Subtitle variant="subtitle1">{getSubtitle(item)}</Subtitle>
              )}
            </Box>
          </Fade>
        </HeaderContent>
      </Header>
      <Container maxWidth={isMobile ? "sm" : "lg"}>
        {renderContent(item)}
        {enableSharing && shareLinks && (
          <>
            <Divider sx={{ my: 6 }} />
            <ShareActions>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Share color="primary" />
                <ShareText variant="h6">
                  Compartilhar nas redes sociais
                </ShareText>
              </Stack>
              <Stack direction="row" spacing={3} mt={3} justifyContent="center">
                {[
                  {
                    icon: Facebook,
                    color: "#1877f2",
                    link: shareLinks.facebook,
                    label: "Facebook",
                  },
                  {
                    icon: Twitter,
                    color: "#1da1f2",
                    link: shareLinks.twitter,
                    label: "Twitter",
                  },
                  {
                    icon: WhatsApp,
                    color: "#25d366",
                    link: shareLinks.whatsapp,
                    label: "WhatsApp",
                  },
                  {
                    icon: Instagram,
                    color: "#E1306C",
                    link: shareLinks.instagram,
                    label: "Instagram",
                    action: handleCopyToClipboard,
                  },
                ].map(({ icon: Icon, color, link, label, action }) => (
                  <Tooltip key={label} title={`Compartilhar no ${label}`}>
                    <IconButton
                      sx={{
                        backgroundColor: `${color}15`,
                        "&:hover": { backgroundColor: `${color}25` },
                      }}
                      onClick={() =>
                        action ? action(link) : window.open(link, "_blank")
                      }
                      aria-label={`Compartilhar no ${label}`}
                      size="large"
                    >
                      <Icon sx={{ color }} />
                    </IconButton>
                  </Tooltip>
                ))}
              </Stack>
            </ShareActions>
          </>
        )}
      </Container>
      <CustomSnackBar
        approvalSnackbarOpen={snackbarOpen}
        handleCloseApprovalSnackbar={() => setSnackbarOpen(false)}
      >
        <Alert severity="success" variant="filled">
          Link copiado com sucesso. Compartilhe já com seus amigos!
        </Alert>
      </CustomSnackBar>
    </AnimatedContainer>
  );
};

export default GenericDetailPage;
