import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { ApplicationState } from "~/redux/store";

const PrivateRoute = ({ requiredRole }: { requiredRole?: string[] }) => {
  const { isLogged, user } = useSelector(
    (state: ApplicationState) => state.auth,
  );

  if (!isLogged) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && user && !requiredRole.includes(user.role)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
