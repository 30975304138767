import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";

import PrivateRoute from "./common/components/private-route/private-route";
import { SessionExpireModal } from "./common/components/session-expire-modal/session-expire-modal";
import { ApplicationState } from "./redux/store";
import { Role } from "~/common/types/user-session";
import AuthorizationErrorModal from "~/common/components/authorization-error-modal/authorization-error-modal";
import { getSocket, initSocket } from "~/common/socket/socket";
import { updateRole } from "~/redux/slicers/authSlicer";
import BackToTopButton from "~/common/components/back-to-top-button/back-to-top-button";
import NotFoundPage from "~/features/not-found/not-found";
import SignIn from "~/features/login";
import Home from "~/features/home";

import StudentArea from "~/features/student-area/screens/student-area";
import CourseDetailsPage from "~/features/student-area/screens/course-details";
import ApprovedList from "./features/approved/screens/approved-list";
import ContactPage from "./features/contact-us/contact-us";
import CoursesDetailsPage from "./features/courses-face-to-face/screens/courses-details/courses-details";
import CoursesFaceToFaceListPage from "./features/courses-face-to-face/screens/courses-list/courses-list";
import DownloadsPage from "./features/downloads/screens/downloads";
import NewsDetailPage from "./features/news/screens/news-details/news-details";
import NewsListPage from "./features/news/screens/news-list/news-list";
import Onboarding from "./features/onboarding";
import OrdersPage from "./features/orders/screens/orders";
import PhotosPage from "./features/photos/screens/photos";
import CartPage from "./features/shop/screens/cart/cart";
import CheckoutPage from "./features/shop/screens/checkout/checkout";
import HandoutsPage from "./features/shop/screens/handouts/handouts";
import ProductPage from "./features/shop/screens/product-page/product-page";
import RecordedCoursesPage from "./features/shop/screens/recorded-courses/recorded-courses";
import UserProfilePage from "./features/user-profile/user-profile";
import RequestPasswordReset from "~/features/reset-password/screens/request-reset";
import LoggedInPasswordReset from "~/features/reset-password/screens/logged-in-reset";
import ResetPassword from "~/features/reset-password/screens/reset-password";

interface AppProps {
  onLoad?: () => void;
}

const AdminPage = React.lazy(() => import("./admin/screens/admin"));

function App({ onLoad }: { onLoad?: () => void }) {
  const dispatch = useDispatch();

  const isSessionExpiredModalOpen = useSelector(
    (state: ApplicationState) => state.session.isSessionExpiredModalOpen,
  );
  const isAuthorizationErrorModalOpen = useSelector(
    (state: ApplicationState) => state.session.isAuthorizationErrorModalOpen,
  );
  const user = useSelector((state: ApplicationState) => state.auth.user);

  const isNotLoginPage = window.location.pathname !== "/login";
  const isNotOnCartPage = window.location.pathname !== "/carrinho";

  useEffect(() => {
    initSocket();
    const socket = getSocket();

    socket.on("connect", () => {
      if (user?.userId) {
        socket.emit("JOIN_ROOM", { userId: user.userId });
      }
    });

    socket.on(
      "USER_ROLE_CHANGED",
      (data: { userId: number; newRole: string }) => {
        dispatch(updateRole(data.newRole));
      },
    );

    return () => {
      socket.off("USER_ROLE_CHANGED");
      socket.off("connect");
    };
  }, [dispatch, user?.userId]);

  useEffect(() => {
    onLoad && onLoad();
  }, [onLoad]);

  return (
    <>
      <Routes>
        <Route
          element={
            <Suspense fallback={<div className="spinner"></div>}>
              <PrivateRoute requiredRole={[Role.ADMIN]} />
            </Suspense>
          }
        >
          <Route path="/admin" element={<AdminPage />} />
        </Route>

        <Route
          element={<PrivateRoute requiredRole={[Role.ALUNO, Role.ADMIN]} />}
        >
          <Route path="/aluno/cursos/:id" element={<CourseDetailsPage />} />
          <Route path="/aluno" element={<StudentArea />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/redefinir-senha" element={<LoggedInPasswordReset />} />
          <Route path="/perfil" element={<UserProfilePage />} />
          <Route path="/downloads" element={<DownloadsPage />} />
          <Route path="/pedidos" element={<OrdersPage />} />
          <Route path="/finalizar-compra" element={<CheckoutPage />} />
        </Route>

        <Route path="/recuperar-senha/:token" element={<ResetPassword />} />
        <Route
          path="/recuperar-senha/solicitar"
          element={<RequestPasswordReset />}
        />
        <Route path="/carrinho" element={<CartPage />} />
        <Route path="/produto/:id" element={<ProductPage />} />
        <Route path="/apostilas" element={<HandoutsPage />} />
        <Route path="/cursos-gravados" element={<RecordedCoursesPage />} />
        <Route
          path="/cursos-presenciais/:id"
          element={<CoursesDetailsPage />}
        />
        <Route
          path="/cursos-presenciais"
          element={<CoursesFaceToFaceListPage />}
        />
        <Route path="/fotos" element={<PhotosPage />} />
        <Route path="/fale-conosco" element={<ContactPage />} />
        <Route path="/noticias" element={<NewsListPage />} />
        <Route path="/noticia/:id" element={<NewsDetailPage />} />
        <Route path="/aprovados" element={<ApprovedList />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/cadastro" element={<Onboarding />} />
        <Route path="/" element={<Home />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {isNotOnCartPage && isNotLoginPage && isSessionExpiredModalOpen && (
        <SessionExpireModal />
      )}
      {isAuthorizationErrorModalOpen && <AuthorizationErrorModal />}
      <BackToTopButton />
    </>
  );
}

export default App;
