import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

export const pageAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const AnimatedContainer = styled(motion.div)``;

export const Header = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(6, 2, 4),
  position: "relative",
  marginBottom: theme.spacing(4),
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    background: `linear-gradient(135deg, ${theme.palette.primary.main}08, ${theme.palette.secondary.main}08)`,
    zIndex: 0,
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 800,
  margin: "-40px auto 2rem",
  borderRadius: theme.shape.borderRadius * 2,
  overflow: "hidden",
  background: theme.palette.background.paper,
  padding: theme.spacing(1),
  boxShadow: "0 8px 32px rgba(0,0,0,0.06)",
}));

export const BackButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  position: "relative",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(1, 2),
  "&:hover": {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[2],
  },
}));

export const HeaderContent = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  maxWidth: theme.breakpoints.values.lg,
  margin: "0 auto",
  padding: theme.spacing(0, 2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  textAlign: "center",
  background: `linear-gradient(135deg, ${theme.palette.text.primary}, ${theme.palette.primary.main})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  letterSpacing: "-0.02em",
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "1.25rem",
  marginBottom: theme.spacing(3),
  maxWidth: "800px",
  margin: "0 auto",
  textAlign: "center",
}));

export const StyledImage = styled(motion.img)({
  width: "100%",
  height: "auto",
  display: "block",
  objectFit: "cover",
});

export const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 10px 30px rgba(0,0,0,0.05)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 15px 35px rgba(0,0,0,0.1)",
  },
}));

export const ContentText = styled(Typography)(({ theme }) => ({
  lineHeight: 1.8,
  fontSize: "1.1rem",
  color: theme.palette.text.primary,
  "& img": {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(4, 0),
    boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
    loading: "lazy",
  },
}));

export const ShareActions = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  background: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
}));

export const ShareText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

export const ChipContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "wrap",
  marginTop: theme.spacing(2),
  justifyContent: "center",
}));
