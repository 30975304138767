import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useIsMobile } from "~/common/hooks/use-is-mobile";

export const FirstHeaderSession = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const sreenSize1024 = useMediaQuery("(max-width:1440px)");

  return (
    <Box sx={{ boxShadow: 1, backgroundColor: "#f0f0f0" }}>
      <Container maxWidth="xl">
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={7} display={isMobile ? "none" : "block"}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={isSmallScreen ? "center" : "flex-start"}
                py={1}
                flexDirection={"row"}
              >
                {!isMobile && (
                  <>
                    <PhoneIcon color="primary" />
                    <Typography
                      pr={2}
                      pl={2}
                      style={{
                        fontSize: isMobile || isSmallScreen ? "12px" : "16px",
                      }}
                    >
                      +55 (18) 99726-1599
                    </Typography>
                    <EmailIcon
                      color="primary"
                      style={{
                        display: sreenSize1024 ? "none" : "block",
                      }}
                    />
                    <Typography
                      pr={2}
                      pl={2}
                      style={{
                        fontSize: isMobile || isSmallScreen ? "12px" : "16px",
                        display: sreenSize1024 ? "none" : "block",
                      }}
                    >
                      contato@crcursospreparatorios.com
                    </Typography>
                  </>
                )}
                <AccessTimeIcon color="primary" />
                <Typography
                  pr={2}
                  pl={2}
                  style={{
                    fontSize: isMobile || isSmallScreen ? "12px" : "16px",
                  }}
                >
                  Seg - Sex: 9:00 - 22:00h
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                display="flex"
                justifyContent={isSmallScreen ? "center" : "flex-end"}
              >
                <IconButton
                  href="https://www.facebook.com/wwwcrcursospreparatorios"
                  target="_blank"
                  aria-label="Acesse nossa página no Facebook"
                >
                  <FacebookIcon color="info" />
                </IconButton>
                <IconButton
                  href="https://wa.me/5518997261599"
                  target="_blank"
                  aria-label="Entre em contato pelo WhatsApp"
                >
                  <WhatsAppIcon color="success" />
                </IconButton>
                <IconButton
                  href="https://www.instagram.com/crcursospreparatorios1/"
                  target="_blank"
                  aria-label="Siga-nos no Instagram"
                >
                  <InstagramIcon style={{ color: "#E4405F" }} />
                </IconButton>
                <IconButton
                  href="https://www.youtube.com/c/CRCursosPreparatorios"
                  target="_blank"
                  aria-label="Inscreva-se no nosso canal do YouTube"
                >
                  <YouTubeIcon color="error" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </Box>
  );
};
