import { SxProps, Theme } from "@mui/material/styles";

export const footerContainer: SxProps<Theme> = {
  backgroundColor: "#002f96",
  color: "white",
  py: 5,
};

export const linkStyle: SxProps<Theme> = {
  cursor: "pointer",
};

export const iconButtonStyle: SxProps<Theme> = {
  color: "white",
  cursor: "pointer",
};

export const socialIconsContainer: SxProps<Theme> = {
  display: "flex",
  gap: 2,
};

export const contactInfoBox: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  mb: 1,
};

export const iconMarginRight: SxProps<Theme> = {
  mr: 1,
};

export const footerBottom: SxProps<Theme> = {
  textAlign: "center",
  mt: 4,
};
