import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";

interface InstallmentsCalculatorProps {
  price: number;
  discountedPrice?: number;
  minInstallmentValue?: number;
  maxInstallments?: number;
}

const InstallmentsCalculator: React.FC<InstallmentsCalculatorProps> = ({
  price,
  discountedPrice,
  minInstallmentValue = 5,
  maxInstallments = 12,
}) => {
  const INSTALLMENT_FACTORS = useMemo(
    () => [
      1.0, 0.5263, 0.35687, 0.27218, 0.22142, 0.1876, 0.16349, 0.14541, 0.13139,
      0.12018, 0.11103, 0.10343,
    ],
    [],
  );

  const installmentInfo = useMemo(() => {
    const activePrice = discountedPrice ?? price;
    let bestInstallment = null;

    for (
      let i = 0;
      i < Math.min(maxInstallments, INSTALLMENT_FACTORS.length);
      i++
    ) {
      const installmentValue = activePrice * INSTALLMENT_FACTORS[i];
      if (installmentValue >= minInstallmentValue) {
        bestInstallment = {
          quantity: i + 1,
          value: installmentValue,
          hasInterest: i > 2,
        };
      }
    }
    return { bestInstallment };
  }, [
    discountedPrice,
    price,
    maxInstallments,
    INSTALLMENT_FACTORS,
    minInstallmentValue,
  ]);

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  if (!installmentInfo.bestInstallment) return null;

  const { bestInstallment } = installmentInfo;

  return (
    <Box>
      {discountedPrice && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textDecoration: "line-through" }}
        >
          {`${bestInstallment.quantity}x de ${formatCurrency(price * INSTALLMENT_FACTORS[bestInstallment.quantity - 1])}`}
        </Typography>
      )}
      <Typography variant="h6" color="primary" fontWeight="bold">
        {`${bestInstallment.quantity}x de ${formatCurrency(bestInstallment.value)}`}
      </Typography>
    </Box>
  );
};

export default InstallmentsCalculator;
