import baseApi from "./base-api";

export const getHandouts = async (pageNumber: number, pageSize: number) => {
  try {
    const response = await baseApi(
      `/products?isHandout=true&page=${pageNumber}&pageSize=${pageSize}`,
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os produtos", error);
  }
};
