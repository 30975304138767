async function fetchAddress(cep: string) {
  if (cep.length === 9) {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    const data = await response.json();
    if (!data.erro) {
      return {
        endereco: data.logradouro,
        cidade: data.localidade,
        uf: data.uf,
        bairro: data.bairro,
      };
    }
  }
  return null;
}

export { fetchAddress };
