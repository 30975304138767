import { io, Socket } from "socket.io-client";
let socket: Socket;

export function initSocket() {
  socket = io(process.env.REACT_APP_BASE_API_URL, {
    transports: ["websocket"],
    reconnectionAttempts: 5,
  });

  socket.on("connect", () => {});

  socket.on("disconnect", () => {});
}

export function getSocket() {
  return socket;
}
