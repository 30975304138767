import { Fab, Zoom, useScrollTrigger, Tooltip } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const BackToTopButton = () => {
  const trigger = useScrollTrigger({
    threshold: 200,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Zoom in={trigger}>
      <Tooltip title="Voltar ao Topo" placement="top">
        <Fab
          color="primary"
          size="small"
          onClick={handleClick}
          aria-label="Voltar ao Topo"
          sx={{
            position: "fixed",
            bottom: 16,
            left: 16,
            zIndex: 1000,
            backgroundColor: "rgba(0, 123, 255, 0.8)",
            "&:hover": {
              backgroundColor: "rgba(0, 123, 255, 1)",
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Zoom>
  );
};

export default BackToTopButton;
